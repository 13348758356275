import React from "react";
import Card from "react-bootstrap/Card";
import { ImBook, ImLeaf } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi there! I'm <span className="purple">Nergiz Omer</span>.
            <br />
            A fresh software engineering graduate from Koya University, I'm a web development enthusiast with a special love for the frontend. I take joy in crafting aesthetically pleasing and creative web applications.
            <br />
            I'm excited about learning new things and I strive to grow personally and professionally every day. My goal is to become a better version of myself with each passing day.
            <br />
            <br />
            When I'm not in the coding zone, you can find me indulging in these activities:
          </p>
          <ul>
            <li className="about-activity">
              <ImBook /> Reading Books
            </li>
            <li className="about-activity">
              <ImLeaf /> Gardening & Planting
            </li>
          </ul>

          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;