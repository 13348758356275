import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

function Github() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        <p>
<p>
  Here are some of my contributions on <strong className="purple">GitHub</strong>. However, I have also been actively involved in private projects on <strong className="purple">Bitbucket</strong>.
</p>        </p>      </h1>
      <GitHubCalendar
        username="NergizOmer"
        blockSize={15}
        blockMargin={5}
        color="#c084f5"
        fontSize={16}

      />
    </Row>
  );
}

export default Github;
